import { useEffect, useState } from 'react';
import './layout.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import Language from '@components/common/Language';
import User from '@components/common/User';
import Sidebar from '@components/common/Sidebar';
import { useTranslation } from 'react-i18next';
import ExportModule from '@components/common/ExportModule';
import TopBanner from '@components/common/TopBanner';
import ApiService from '@services/ApiService';

const Mobile = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [display, setDisplay] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [userInfo, setUserInfo] = useState(null as any);
  const [active, setActive] = useState(false);
  const [permissions, setPermissions] = useState(null as any);
  const { t } = useTranslation();
  const [banners, setBanners] = useState([] as any);
  const [menuPosition, setMenuPosition] = useState(54);

  useEffect(() => {
    const usr = localStorage.getItem('usr');

    if (!usr) {
      if (location.pathname !== '/forgot-password' && location.pathname !== '/sign-in' && location.pathname !== '/reset-password') {
        navigate('/sign-in');
      }
    } else {
      if (location.pathname === '/forgot-password' || location.pathname === '/sign-in' || location.pathname === '/reset-password') {
        navigate('/');
      } else {
        setUserInfo(JSON.parse(usr));
        setDisplay(true);
      }
    }
  }, [navigate, location]);

  useEffect(() => {
    if (userInfo) {
      const roles = [] as any;

      userInfo.permissions.forEach((item: any) => {
        const obj = {
          id: (item.resource !== "") ? item.resource : item.app,
          permissions: item.action,
          parrent: item.app
        };

        roles.push(obj);

        if (location.pathname.indexOf(obj.id) !== -1) {
          setActive(true);

          if (location.pathname.indexOf('edit') !== -1) {
            if (!item.action.includes('edit')) {
              setActive(false);
            }
          } else if (location.pathname.indexOf('create') !== -1) {
            if (!item.action.includes('create')) {
              setActive(false);
            }
          }
        }

        setPermissions(roles)

      });

      if (location.pathname === '/change-password' || location.pathname.indexOf('/profile') !== -1) {
        setActive(true);
      }

      fetchTopBanner();
    }
  }, [userInfo, location]);

  const fetchTopBanner = async () => {
    try {
      const res = await ApiService.get('organization/banners/workspace');

      if (res?.data?.length > 0) {
        setBanners(res?.data);
      }
    } catch (error: any) {
      console.log(error?.message);
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', handleHeaderMobileSticky, { passive: true });
    document.addEventListener('touchmove', handleHeaderMobileSticky, { passive: true });

    return () => {
      document.removeEventListener('scroll', handleHeaderMobileSticky);
      document.removeEventListener('touchmove', handleHeaderMobileSticky);
    };

    // eslint-disable-next-line
  }, []);

  const handleHeaderMobileSticky = () => {
    const position = window.pageYOffset;

    if (position === 0) {
      setMenuPosition(54);
    } else {
      if (position < 54) {
        setMenuPosition(position);
      } else {
        setMenuPosition(0);
      }
    }
  }

  return (
    <div id="mobile">
      {
        display ?
          <div className="w-full h-full">
            {
              openMenu ?
                <div className="menu-fixed">
                  <div className="flex w-full h-full">
                    {
                      permissions &&
                      <Sidebar
                        permissions={permissions}
                      />
                    }

                    <div className="h-[72px] items-center flex shadow shadow-blue-gray-300 mobile-header header-collapse"
                      onClick={() => setOpenMenu(false)}>
                      <img src="/images/icons/menu-open.svg" alt="icon-menu-open" />
                    </div>
                  </div>
                </div>
                :
                <div className='relative'>
                  <div id="menu" className={`menu-fixed ${(banners.length > 0 ? `!top-[${menuPosition}px]` : '')}`}>
                    {
                      banners.length > 0 &&
                      <TopBanner banners={banners} isMobile={true} />
                    }

                    <div className="w-full h-[72px] bg-white items-center justify-between flex shadow shadow-blue-gray-300 mobile-header">
                      <img src="/images/icons/menu.svg" alt="icon-menu" onClick={() => setOpenMenu(true)} />

                      <div className="flex justify-end mr-2 items-center">
                        <ExportModule
                          t={t} />

                        <Language
                          mobile={true}
                        />

                        {
                          userInfo &&
                          <User
                            userInfo={userInfo}
                          />
                        }
                      </div>

                    </div>
                  </div>
                </div>
            }

            {
              active ?
                <div className={`${openMenu ? "layout-content overlay" : "layout-content"} ${banners.length > 0 ? 'mt-[126px]' : 'mt-[72px]'}`}>{props.children}</div>
                :
                <div className={openMenu ? "layout-content overlay" : "layout-content"}>
                  <div className="access-denied">
                    <div className="access-denied__label">{t('access-denied')}</div>
                    <div className="access-denied__content">{t('access-denied-content')}</div>
                    <div className="access-denied__content">{t('access-denied-content-2')}</div>
                  </div>
                </div>
            }
          </div>
          :
          <>{props.children}</>
      }
    </div>
  )
}

export default Mobile;