import useWindowWidth from "@helpers/InitWidthDevice";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import EditProfileUI from "UI/desktop/Profile/Edit";
import EditProfileUIMobile from "UI/mobile/Profile/Edit";
import Message from '@components/common/Message';
import Loader from '@components/common/Loader';
import { FileUploader } from "react-drag-drop-files";
import ApiService from '@services/ApiService';
import { Formik, Field, Form } from 'formik';
import Account from '@helpers/Interface';
import { Button, Select, Option } from "@material-tailwind/react";
import { COUNTRY } from '@helpers/constants';
import { CameraIcon } from '@heroicons/react/20/solid';
import { get } from "lodash";

const fileTypes = ["JPG", "PNG", "JPEG"];

const FormEditProfile = (props: any) => {
  const { t } = props;
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [loading, setLoading] = useState(false);
  const [avatar, setAvatar] = useState('');
  const [phoneCode, setPhoneCode] = useState('84');
  const [profile, setProfile] = useState(null as any);
  const [events, setEvents] = useState(null as any);
  const [fetchData, setFetchData] = useState(false);
  const { i18n: { language } = {} } = useTranslation();

  useEffect(() => {
    const intervalFetchData = setInterval(() => {
      setFetchData(true);
      clearInterval(intervalFetchData);
    }, 100);
  }, []);

  useEffect(() => {
    if (fetchData) {
      fetchProfile()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  const fetchProfile = async () => {
    setLoading(true);
    try {
      const result = await ApiService.get(`account/profile`);

      if (result) {
        setProfile(result);

        if (result.avatar && result.avatar !== '') {
          setAvatar(result.avatar);
        }

        if (result.phone_area_code !== '') {
          setPhoneCode(result.phone_area_code);
        }

        if (result.events && result.events.length > 0) {
          fetchEvents(result.events);
        }
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
      setLoading(false);
    }

    setFetchData(false);
  }

  const fetchEvents = async (e: any) => {
    try {
      const result = await ApiService.get(`events`);

      if (result && result.length > 0) {
        const arr = [] as any;
        result.forEach((event: any) => {
          for (let i = 0; i < e.length; i++) {
            if (e[i] === event.id) {
              arr.push(get(event, `name_${language}`, ''));
            }
          }
        });
        
        setEvents([...arr]);
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }

    setLoading(false);
    setFetchData(false);
  }

  const handleUploadImage = async (img: any) => {
    setLoading(false);
    const data = new FormData();
    data.append('upload', img, `${img.name}`);

    try {
      const result = await ApiService.uploadImage(data);

      if (result) {
        setAvatar(result.img_url);
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }

    setLoading(false);
  }

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    setLoading(true);
    const submitData = {
      avatar: avatar,
      last_name: values.last_name,
      first_name: values.first_name,
      phone: values.phone,
      phone_area_code: phoneCode
    };

    try {
      const result = await ApiService.put(`account/profile`, submitData);

      if (result) {
        setMessage(t('update-success'));
        setMessageType('success');
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }

    setLoading(false);
    setSubmitting(false);
  }

  const validateText = (value: string) => {
    let error;

    if (!value) {
      error = t("error-field-require");
    } else {
      let str = value;
      str = str.toLowerCase();
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
      str = str.replace(/đ/g, 'd');
      const regex = /[^a-zA-Z0-9 ]+/;

      if (regex.test(str)) {
        error = t("error-special-character");
      }
    }

    return error;
  }

  return (
    <div className="form-profile">
      <div className="form-info">
        <div className="form-header">
          <div className="header-label">{t('edit-profile')}</div>
        </div>

        <div className="form-body">
          <div className="avatar-group">
            <FileUploader
              handleChange={(e: any) => handleUploadImage(e)}
              name="file"
              types={fileTypes}
              classes="upload-image-group"
            >
              {
                (avatar === '') ?
                  <CameraIcon className="h-8 w-8 icon-upload-image" />
                  :
                  <img src={avatar}
                    alt="user-avatar" className="avatar" />
              }
            </FileUploader>

          </div>

          {
            profile &&
            <Formik
              initialValues={{
                first_name: profile.first_name,
                last_name: profile.last_name,
                phone: profile.phone
              } as Account}
              onSubmit={(values, { setSubmitting }) => handleSubmit(values, { setSubmitting })}
            >
              {({
                handleSubmit,
                isSubmitting
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="form">
                    <div className="form-row">

                      <div className="form-item">
                        <div className="form-label-group">
                          <span className="form-label">{t('first-name')}</span>

                          <img src="/images/icons/icon-require.svg" alt="icon-require" className="icon-require" />
                        </div>

                        <Field name="first_name" validate={validateText}>
                          {({
                            field,
                            meta,
                          }: any) => (
                            <div className="field-group">
                              <input {...field} type="text" className="input" autoComplete="off" placeholder={t('first-name')} maxLength={50} />

                              <div className="text-length">{field.value.length}/50</div>

                              {meta.touched && meta.error && (
                                <div className="field-error">{meta.error}</div>
                              )}
                            </div>
                          )}
                        </Field>

                      </div>

                      <div className="form-item">
                        <div className="form-label-group">
                          <span className="form-label">{t('last-name')}</span>

                          <img src="/images/icons/icon-require.svg" alt="icon-require" className="icon-require" />
                        </div>

                        <Field name="last_name" validate={validateText}>
                          {({
                            field,
                            meta,
                          }: any) => (
                            <div className="field-group">
                              <input {...field} type="text" className="input" autoComplete="off" placeholder={t('last-name')} maxLength={50} />

                              <div className="text-length">{field.value.length}/50</div>

                              {meta.touched && meta.error && (
                                <div className="field-error">{meta.error}</div>
                              )}
                            </div>
                          )}
                        </Field>

                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-item">
                        <div className="form-label-group">
                          <span className="form-label">{t('role-name')}</span>
                        </div>

                        <div className="field-group">
                          <input type="text" className="input disabled" value={profile.role} disabled={true} />
                        </div>

                      </div>

                      <div className="form-item">
                        <div className="form-label-group">
                          <span className="form-label">{t('contact-phone')}</span>
                        </div>

                        <div className="phone-group">
                          <div className="select-phone-code">
                            <Select
                              value={phoneCode}
                              onChange={(val: any) => setPhoneCode(val)}
                              placeholder=""
                              onPointerEnterCapture="none" onPointerLeaveCapture="none"
                            >
                              {
                                COUNTRY.map((item: any, index: number) => {
                                  return (
                                    <Option key={`${item.value}_${index}`}
                                      value={item.code}>{item.value} | +{item.code}</Option>
                                  )
                                })
                              }
                            </Select>
                          </div>

                          <Field name="phone" >
                            {({
                              field,
                              meta,
                            }: any) => (
                              <div className="input-phone-group">
                                <input {...field} type="text" className="input-phone" autoComplete="off" placeholder={t('phone-number')} />
                              </div>
                            )}
                          </Field>
                        </div>

                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-item">
                        <div className="form-label-group">
                          <span className="form-label">Email</span>
                        </div>

                        <div className="field-group">
                          <input type="text" className="input disabled" autoComplete="off" value={profile.email} disabled={true} />
                          <div className="field-note">{t('email-note')}</div>
                        </div>

                      </div>

                      <div className="form-item">
                        <div className="form-label-group">
                          <span className="form-label">{t("event")}</span>
                        </div>

                        <div className="field-group">
                          {
                            events && events.length > 0 ?
                            <div className="event-list">
                              {
                                events.map((item: any, index: number) => {
                                  return (
                                    <div className="event-item" key={`${item}_${index}`}>{item}</div>
                                  )
                                })
                              }
                            </div>
                            :
                            <input type="text" className="input disabled" disabled={true} />
                          }
                        </div>

                      </div>


                    </div>
                  </div>

                  <div className="btn-group">
                    <Button
                      onPointerEnterCapture="none" onPointerLeaveCapture="none"
                      placeholder="Button Cancel" className="btn-cancel" onClick={() => window.history.back()}>{t('btn-cancel')}</Button>
                    <Button
                      onPointerEnterCapture="none" onPointerLeaveCapture="none"
                      type="submit" disabled={isSubmitting} placeholder="Button Save" className="btn-save">{t('btn-save')}</Button>
                  </div>

                  {
                    message !== '' &&
                    <Message
                      message={message}
                      messageType={messageType}
                      setMessage={setMessage}
                    />
                  }

                  {
                    loading &&
                    <Loader />
                  }
                </Form>
              )}
            </Formik>
          }

        </div>
      </div>

      {
        message !== '' &&
        <Message
          message={message}
          messageType={messageType}
          setMessage={setMessage}
        />
      }

      {
        loading &&
        <Loader />
      }
    </div>
  )
}

const EditProfile = () => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();

  if (windowWidth && windowWidth > 768) {
    return (
      <EditProfileUI
        t={t}
        FormEditProfile={FormEditProfile}
      />
    )
  } else {
    return (
      <EditProfileUIMobile
        t={t}
        FormEditProfile={FormEditProfile}
      />
    )
  }
}

export default EditProfile;