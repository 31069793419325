import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import CarouselMobile from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ArrowDownIcon, ArrowLeftIcon, ArrowRightIcon, ArrowUpIcon } from '@heroicons/react/20/solid';
import Select from 'react-select';
import {
  Typography
} from "@material-tailwind/react";
import DateRangePicker from '@components/common/DateRangePicker';
import ApiService from '@services/ApiService';
import Message from '@components/common/Message';
import Loader from '@components/common/Loader';
import PaginationMain from '@components/common/Pagination';
import { get } from 'lodash';
import ChartOverview from '../Chart';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Grid, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const responsive = {
  desktop: {
    breakpoint: { max: 7000, min: 768 },
    items: 4
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1
  }
};

const SLIDER_BREAKPOINT: any = {
  768: {
    slidesPerView: 2,
    spaceBetween: 15,
    slidesPerGroup: 3,
    allowTouchMove: false,
    grid: {
      rows: 1,
      fill: "row"
    }
  },
  860: {
    slidesPerView: 3,
    spaceBetween: 15,
    slidesPerGroup: 3,
    allowTouchMove: false,
    grid: {
      rows: 1,
      fill: "row"
    }
  },
  1140: {
    slidesPerView: 4,
    spaceBetween: 15,
    slidesPerGroup: 4,
    allowTouchMove: false,
    grid: {
      rows: 1,
      fill: "row"
    }
  },
};

const Events = (props: any) => {
  const { t, mobile, applyDate, setLoading, setMessage, setMessageType, page, setPage, setFilter, filter, shortenNumber, language, setEventSelected, setCurrentTab, setEventID, eventID, eventStatus, setEventStatus, navigate } = props;
  const [paging, setPaging] = useState(null as any);
  const [orders, setOrders] = useState([] as any);
  const status = [
    {
      value: '',
      label: t('event-status')
    },
    {
      value: 'coming_soon',
      label: t('coming-soon')
    },
    {
      value: 'closed',
      label: t('closed')
    },
    {
      value: 'on_selling',
      label: t('on-selling')
    }
  ] as any;
  const [events, setEvents] = useState([] as any);
  const [fetchData, setFetchData] = useState(false);
  const [statusSelected, setStatusSelected] = useState(null as any);
  const [eventDefault, setEventDefault] = useState(null as any);

  useEffect(() => {
    if (events?.length > 0) {
      if (eventID !== '') {
        events.forEach((item: any) => {
          if (item.value === eventID) {
            setEventDefault(item);
          }
        })
      } else {
        setEventDefault(events[0]);
      }
    }
  }, [eventID, events]);

  useEffect(() => {
    if (eventStatus !== '') {
      status.forEach((item: any) => {
        if (eventStatus === item.value) {
          setStatusSelected(item);
        }
      })
    } else {
      setStatusSelected(status[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventStatus]);

  useEffect(() => {
    if (filter) {
      const intervalFetchData = setInterval(() => {
        setFetchData(true);
        clearInterval(intervalFetchData);
      }, 100);
    }
  }, [filter]);

  useEffect(() => {
    if (fetchData) {
      if (events.length === 0) {
        fetchEventList();
      } else {
        if (page === 0) {
          setPage(1)
        };
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, fetchData]);

  const fetchEventList = async () => {
    try {
      const result = await ApiService.get(`events`);

      if (result) {
        const arr = [] as any;

        result.forEach((item: any) => {
          const obj = {
            value: item.id,
            label: get(item, `name_${language}`, '')
          };

          arr.push(obj);
        })
        setEvents(arr);
        setPage(1);
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
      setLoading(false);
    }
  }

  useEffect(() => {
    if (page > 0 && filter) {
      fetchOrders();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filter]);

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const result = await ApiService.get(`orders/sales/page/${page}?from_date=${applyDate.start}&to_date=${applyDate.end}&event_id=${eventID}&event_status=${eventStatus}&limit=10`);

      if (result) {
        setPaging({ ...result.paging });
        setOrders(result.data);
        setFilter(false);
      }

    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }
    setFetchData(false);
    setLoading(false);
  }

  const handleExportData = async () => {
    setLoading(true);

    try {
      const result = await ApiService.get(`exports/event?from_date=${applyDate.start}&to_date=${applyDate.end}&event_id=${eventID}&event_status=${eventStatus}`);

      if (result) {
        setMessage(t('export-success'));
        setMessageType('success');
      }

    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }
    setLoading(false);
  }

  return (
    <div className="events-board">
      <div className="events-header">
        <div className="header-label">{t("event")}</div>

        <div className="filters">
          {
            events.length > 0 &&
            <div className="filter-event">
              <div className="filter-label">{t("event-name")}</div>

              {
                eventDefault &&
                <Select
                  defaultValue={eventDefault}
                  name="events"
                  options={events}
                  className="multi-select"
                  classNamePrefix="select"
                  placeholder={events[0].label}
                  onChange={(e: any) => {
                    setEventID(e.value);
                    setFilter(true);
                    setPage(0);
                  }} />
              }

            </div>
          }

          <div className="filter-status">
            <div className="filter-label">{t("status")}</div>

            {
              statusSelected &&
              <Select
                defaultValue={statusSelected}
                name="events"
                options={status}
                className="multi-select"
                classNamePrefix="select"
                placeholder={status[0].label}
                onChange={(e: any) => {
                  setEventStatus(e.value);
                  setFilter(true);
                  setPage(0);
                }} />
            }

          </div>

          <div className={`${mobile ? 'w-[100%]' : 'w-[146px] ml-[10px]'} h-[38px] rounded-[4px] border-[1px] border-[#A6A1FD] flex items-center justify-center cursor-pointer text-[#081774] btn-export-group mt-[25px]`} onClick={handleExportData}>
            <img src="/images/icons/icon-download.svg" className="mr-[4px]" alt="icon-download" />

            <div className="text-[14px]">{t('export-report')}</div>
          </div>

        </div>
      </div>

      <div className="events-body">
        {
          !mobile ?
            <div className='h-full w-full overflow-scroll overflow-y-hidden'>
              <table className="w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    <th className='p-4 stt'>
                      <Typography
                        variant="small"
                        className="table-label"
                        placeholder="Typography"
                        onPointerEnterCapture="none" onPointerLeaveCapture="none"
                      >
                        {t('no')}
                      </Typography>
                    </th>

                    <th className='p-4 event-name'>
                      <Typography
                        variant="small"
                        className="table-label"
                        placeholder="Typography"
                        onPointerEnterCapture="none" onPointerLeaveCapture="none"
                      >
                        {t('event')}
                      </Typography>
                    </th>

                    <th className='p-4'>
                      <Typography
                        variant="small"
                        className="table-label"
                        placeholder="Typography"
                        onPointerEnterCapture="none" onPointerLeaveCapture="none"
                      >
                        {t('gross-revenue')}
                      </Typography>
                    </th>

                    <th className='p-4'>
                      <Typography
                        variant="small"
                        className="table-label"
                        placeholder="Typography"
                        onPointerEnterCapture="none" onPointerLeaveCapture="none"
                      >
                        {t('order')}
                      </Typography>
                    </th>

                    <th className='p-4'>
                      <Typography
                        variant="small"
                        className="table-label"
                        placeholder="Typography"
                        onPointerEnterCapture="none" onPointerLeaveCapture="none"
                      >
                        {t('items')}
                      </Typography>
                    </th>

                    {/* <th className='p-4'>
                      <Typography
                        variant="small"
                        className="table-label"
                        placeholder="Typography"
                        onPointerEnterCapture="none" onPointerLeaveCapture="none"
                      >
                        {t('attendees')}
                      </Typography>
                    </th> */}

                    <th className='p-4'>
                      <Typography
                        variant="small"
                        className="table-label"
                        placeholder="Typography"
                        onPointerEnterCapture="none" onPointerLeaveCapture="none"
                      >
                        {t('discounts')}
                      </Typography>
                    </th>

                    <th className='p-4'>
                      <Typography
                        variant="small"
                        className="table-label"
                        placeholder="Typography"
                        onPointerEnterCapture="none" onPointerLeaveCapture="none"
                      >
                        {t('discount-payment-gateway')}
                      </Typography>
                    </th>

                    <th className='p-4'>
                      <Typography
                        variant="small"
                        className="table-label"
                        placeholder="Typography"
                        onPointerEnterCapture="none" onPointerLeaveCapture="none"
                      >
                        {t('real-revenue')}
                      </Typography>
                    </th>

                    <th className='p-4'>
                      <Typography
                        variant="small"
                        className="table-label"
                        placeholder="Typography"
                        onPointerEnterCapture="none" onPointerLeaveCapture="none"
                      >
                        {t('status')}
                      </Typography>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    orders.length > 0 &&
                    orders.map((item: any, index: number) => {

                      return (
                        <tr key={`order_${item.event_id}_${index}`} className='table-row'>
                          <td className="w-[56px] pl-[16px]">
                            <Typography
                              variant="small"
                              placeholder="Typography"
                              onPointerEnterCapture="none" onPointerLeaveCapture="none"
                            >
                              {
                                index < 9 &&
                                <span>{paging.current_page - 1}{index + 1}</span>
                              }

                              {
                                index === 9 &&
                                <span>{paging.current_page}0</span>
                              }
                            </Typography>
                          </td>
                          <td className="w-[222px]">
                            <Typography
                              variant="small"
                              placeholder="Typography"
                              onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              className='event-name'
                              onClick={() => {
                                setEventSelected(item.event_id);
                                navigate(window.location.pathname, { replace: true });
                                setCurrentTab(1);
                              }}
                            >
                              {item.event_name}
                            </Typography>
                          </td>
                          <td className='p-4'>
                            <Typography
                              onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              variant="small"
                              placeholder="Typography"
                            >
                              <LightTooltip title={`đ ${(+item.subtotal > 0) ? item.subtotal.toLocaleString('de-DE') : item.subtotal}`} placement='top-start'>
                                <span className="cursor-pointer">đ {shortenNumber(item.subtotal)}</span>
                              </LightTooltip>
                            </Typography>
                          </td>
                          <td className='p-4'>
                            <Typography
                              onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              variant="small"
                              placeholder="Typography"
                            >
                              <LightTooltip title={`${(+item.transactions > 0) ? item.transactions.toLocaleString('de-DE') : item.transactions}`} placement='top-start'>
                                <span className="cursor-pointer">{shortenNumber(item.transactions)}</span>
                              </LightTooltip>
                            </Typography>
                          </td>
                          <td className='p-4'>
                            <Typography
                              onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                              placeholder="Typography"
                            >
                              <LightTooltip title={`${(+item.total_items > 0) ? item.total_items.toLocaleString('de-DE') : item.total_items}`} placement='top-start'>
                                <span className="cursor-pointer">{shortenNumber(item.total_items)}</span>
                              </LightTooltip>
                            </Typography>
                          </td>
                          {/* <td className='p-4'>
                            <Typography
                              onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                              placeholder="Typography"
                            >
                              <Tooltip content={`${(+item.attendants > 0) ? item.attendants.toLocaleString('de-DE') : item.attendants}`}>
                                <span className="cursor-pointer">{shortenNumber(item.attendants)}</span>
                              </Tooltip>
                            </Typography>
                          </td> */}
                          <td className='p-4'>
                            <Typography
                              onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                              placeholder="Typography"
                            >
                              <LightTooltip title={`đ ${(+item.discount_coupon > 0) ? item.discount_coupon.toLocaleString('de-DE') : item.discount_coupon}`} placement='top-start'>
                                <span className="cursor-pointer">đ {shortenNumber(item.discount_coupon)}</span>
                              </LightTooltip>
                            </Typography>
                          </td>
                          <td className='p-4'>
                            <Typography
                              onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                              placeholder="Typography"
                            >
                              <LightTooltip title={`đ ${(+item.discount_promotion > 0) ? item.discount_promotion.toLocaleString('de-DE') : item.discount_promotion}`} placement='top-start'>
                                <span className="cursor-pointer">đ {shortenNumber(item.discount_promotion)}</span>
                              </LightTooltip>
                            </Typography>
                          </td>
                          <td className='p-4'>
                            <Typography
                              onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                              placeholder="Typography"
                            >
                              <LightTooltip title={`đ ${(+item.grand_total > 0) ? item.grand_total.toLocaleString('de-DE') : item.grand_total}`} placement='top-start'>
                                <span className="cursor-pointer">đ {shortenNumber(item.grand_total)}</span>
                              </LightTooltip>
                            </Typography>
                          </td>
                          <td className='p-4'>
                            {
                              item.selling_type === 'on_selling' &&
                              <div className="status opening">{t('opening')}</div>
                            }
                            {
                              item.selling_type === 'closed' &&
                              <div className="status closed">{t('closed')}</div>
                            }
                            {
                              item.selling_type === 'coming_soon' &&
                              <div className="status coming">{t('coming')}</div>
                            }
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            :
            <div className="event-list">
              {
                orders.length > 0 &&
                orders.map((item: any, index: number) => {
                  return (
                    <div className="event-item" key={`event_${item.id}_${index}`}>
                      <div className="event-item__row">
                        <div className="row-label">{t('no')}</div>
                        <div className="row-value">{index + 1}</div>
                      </div>

                      <div className="event-item__row">
                        <div className="row-label">{t('status')}</div>
                        <div className="row-value">
                          {
                            item.selling_type === 'on_selling' &&
                            <div className="status opening">{t('opening')}</div>
                          }
                          {
                            item.selling_type === 'closed' &&
                            <div className="status closed">{t('closed')}</div>
                          }
                          {
                            item.selling_type === 'coming_soon' &&
                            <div className="status coming">{t('coming')}</div>
                          }
                        </div>
                      </div>

                      <div className="event-item__row">
                        <div className="row-label">{t('event')}</div>
                        <div className="row-value">{item.event_name}</div>
                      </div>

                      <div className="event-item__row">
                        <div className="row-label">{t('gross-revenue')}</div>
                        <div className="row-value">{shortenNumber(item.subtotal)}</div>
                      </div>

                      <div className="event-item__row">
                        <div className="row-label">{t('order')}</div>
                        <div className="row-value">{item.transactions}</div>
                      </div>

                      <div className="event-item__row">
                        <div className="row-label">{t('items')}</div>
                        <div className="row-value">{item.total_items}</div>
                      </div>

                      {/* <div className="event-item__row">
                        <div className="row-label">{t('attendees')}</div>
                        <div className="row-value">{item.attendants}</div>
                      </div> */}

                      <div className="event-item__row">
                        <div className="row-label">{t('discounts')}</div>
                        <div className="row-value">{shortenNumber(item.discount_coupon)}</div>
                      </div>

                      <div className="event-item__row">
                        <div className="row-label">{t('promotion')}</div>
                        <div className="row-value">{shortenNumber(item.discount_promotion)}</div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
        }

        {
          (paging) &&
          <div className="board__bottom">
            {
              paging.total_page > 1 &&
              <PaginationMain
                setPage={setPage}
                paging={paging}
                setFilter={setFilter}
              />
            }
          </div>
        }

      </div>
    </div>
  )
}

const CustomDot = ({ ...rest }) => {
  const {
    active,
  } = rest;

  return (
    <div
      className={active ? "dot dot-active" : "dot dot-inactive"}
    >
    </div>
  );
};

const Overview = (props: any) => {
  const { t, i18n: { language } = {} } = useTranslation();
  const { mobile, setEventSelected, setCurrentTab, eventID, setEventID } = props;
  const [applyDate, setApplyDate] = useState({
    start: '',
    end: ''
  });
  const [filter, setFilter] = useState(false);
  const [stats, setStats] = useState(null as any);
  const [growth, setGrowth] = useState(null as any);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [suffix, setSuffix] = useState('');
  const [page, setPage] = useState(0);
  const [fetchData, setFetchData] = useState(false);
  const [searchParams] = useSearchParams();
  const paramsObject = Object.fromEntries([...searchParams]);
  const navigate = useNavigate();
  const [initParams, setInitParams] = useState(true);
  const [showEvent, setShowEvent] = useState(false);
  const [eventStatus, setEventStatus] = useState('');
  const [dateType, setDateType] = useState('year');
  const [dateValue, setDateValue] = useState('');
  const [updateRoute, setUpdateRoute] = useState(false);

  useEffect(() => {
    if (initParams) {
      const keys = Object.keys(paramsObject);

      if (keys?.length > 0) {
        handleInitSearchValue();
      } else {
        setFilter(true);
        setShowEvent(true);
        setInitParams(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initParams]);

  const handleInitSearchValue = () => {
    if (paramsObject.from_date && paramsObject.from_date !== '') {
      setApplyDate({
        start: paramsObject.from_date,
        end: paramsObject.to_date
      })
    }

    if (paramsObject.event_status && paramsObject.event_status !== '') {
      setEventStatus(paramsObject.event_status);
    }

    if (paramsObject.event_id && paramsObject.event_id !== '') {
      setEventID(paramsObject.event_id);
    }

    if (paramsObject.date_type && paramsObject.date_type !== '') {
      setDateType(paramsObject.date_type);
    }

    if (paramsObject.date_value && paramsObject.date_value !== '') {
      setDateValue(paramsObject.date_value);
    }

    setFilter(true);
    setInitParams(false);

    const intervalShowEvent = setInterval(() => {
      setShowEvent(true);
      clearInterval(intervalShowEvent);
    }, 1000);

  }

  useEffect(() => {
    if (filter) {
      const intervalFetchData = setInterval(() => {
        setFetchData(true);
        clearInterval(intervalFetchData);
      }, 100);
    }
  }, [filter]);

  useEffect(() => {
    if (updateRoute) {
      handleUpdateRoute();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyDate, eventStatus, dateType, dateValue, eventID])

  const handleUpdateRoute = () => {
    const newSearchParams = new URLSearchParams(searchParams);

    newSearchParams.set('from_date', applyDate.start);
    newSearchParams.set('to_date', applyDate.end);
    newSearchParams.set('event_status', eventStatus);
    newSearchParams.set('event_id', eventID);
    newSearchParams.set('date_type', dateType);
    newSearchParams.set('date_value', dateValue);

    const newParamsObject = Object.fromEntries([...newSearchParams]);

    const keys = Object.keys(newParamsObject);
    let changeRoute = false;

    if (keys?.length > 0) {
      keys?.forEach((key: string) => {
        if (newParamsObject[key] !== '') {
          changeRoute = true;
        }
      });
    }

    if (changeRoute || !initParams) {
      navigate(`?${newSearchParams.toString()}`, { replace: true });
    }
  }

  useEffect(() => {
    if (fetchData) {
      fetchStats();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  const fetchStats = async () => {
    setLoading(true);
    try {
      const result = await ApiService.get(`dashboards/orders/stats?from_date=${applyDate.start}&to_date=${applyDate.end}`);

      if (result && result.stats) {
        setStats(result.stats);

        if (result.growth_rates && Object.keys(result.growth_rates).length > 0) {
          const keys = Object.keys(result.growth_rates);

          keys.forEach((item: any) => {
            if (result.growth_rates[item] !== "Infinity") {
              result.growth_rates[item] = result.growth_rates[item].toFixed(0);
              result.growth_rates[item] = result.growth_rates[item].toString();
            }
          });

          setGrowth(result.growth_rates);
        } else {
          setGrowth(null);
        }
      }

      setUpdateRoute(true);
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }
    setFetchData(false);
    setLoading(false);
  }

  const shortenNumber = (num: number) => {
    if (num > 0) {
      if (num >= 1000000000000) {
        let shortened = (num / 1000000000000) as any;
        shortened = (Math.floor(shortened * 10) / 10).toFixed(1);
        return shortened.endsWith('.0') ? shortened.slice(0, -2) + 'T' : shortened + 'T';
      } else if (num >= 1000000000) {
        let shortened = (num / 1000000000) as any;
        shortened = (Math.floor(shortened * 10) / 10).toFixed(1);
        return shortened.endsWith('.0') ? shortened.slice(0, -2) + 'B' : shortened + 'B';
      } else if (num >= 1000000) {
        let shortened = (num / 1000000) as any;
        shortened = (Math.floor(shortened * 10) / 10).toFixed(1);
        return shortened.endsWith('.0') ? shortened.slice(0, -2) + 'M' : shortened + 'M';
      } else if (num >= 1000) {
        let shortened = (num / 1000) as any;
        shortened = (Math.floor(shortened * 10) / 10).toFixed(1);
        return shortened.endsWith('.0') ? shortened.slice(0, -2) + 'K' : shortened + 'K';
      } else {
        return num;
      }
    } else {
      return 0;
    }
  }

  const getSuffix = () => {
    switch (suffix) {
      case 'today':
        return t('vs-yesterday');
      case 'yesterday':
        return t('vs-previous-day');
      case 'l7d':
        return t('l7d');
      case 'l30d':
        return t('l30d');
      case '':
        return '';
      default:
        return suffix;
    }
  }

  const getGrowthValue = (value: any) => {
    let final_value = value;
    if (value.indexOf('-') !== -1) {
      final_value = value.slice(1);
    };

    return final_value;
  }

  if (!initParams) {
    return (
      <div className="overview">
        <div className="overview-board">
          <div className="filter-group">
            <div className="filter-label">{t('time')}</div>

            <div className="date-picker">
              <DateRangePicker
                quickSelectDate={true}
                mobile={mobile}
                setApplyDate={setApplyDate}
                setFilter={setFilter}
                setSuffix={setSuffix}
                setPage={setPage}
                applyDate={applyDate}
              />
            </div>
          </div>

          {
            stats &&
            <div className="carousel">
              {
                !mobile ?
                  <div className='carousel-list-item'>
                    <button className="icon-arrow prev-arrow">
                      <ArrowLeftIcon className="w-4 h-4 text-brand-500 arrow" />
                    </button>
                    <Swiper
                      slidesPerView={2}
                      spaceBetween={12}
                      slidesPerGroup={4}
                      className="!pb-10 sm:!pb-0"
                      grid={{
                        rows: 2,
                        fill: "row"
                      }}
                      navigation={{
                        nextEl: ".next-arrow",
                        prevEl: ".prev-arrow",
                        disabledClass: "opacity-0"
                      }}
                      pagination={{
                        el: "#bulletsContainer",
                        type: "bullets",
                        clickable: true,
                        renderBullet: function (index: number, className: string) {
                          return '<span class="' + className + '"></span>';
                        },
                        bulletClass: "sm:hidden !rounded-md !w-10 !h-2 bg-magnolia-400",
                        bulletActiveClass: "!bg-white",
                      }}
                      breakpoints={SLIDER_BREAKPOINT}
                      modules={[Navigation, Grid, Pagination]}
                    >
                      <SwiperSlide className="!h-auto">
                        <div className="carousel-item">
                          <div className="carousel-icon-group group-revenue">
                            <img src="/images/icons/icon-money.svg" alt="icon-overview" />
                          </div>

                          <div className="carousel-info">
                            <div className="info-label">
                              <div>{t('gross-revenue')}</div>

                              <LightTooltip title={t("tooltip-revenue")} className="w-[200px]" placement='top-start'>
                                <img src="/images/icons/tooltip.svg" alt="icon-tooltip" className="icon-tooltip" />
                              </LightTooltip>
                            </div>

                            <div className="info-main-value">
                              <div className='shorten'>đ {shortenNumber(stats.total_subtotal)}</div>
                              {
                                stats.total_subtotal > 0 ?
                                  <div className='full-number'>đ {stats.total_subtotal.toLocaleString('de-DE')}</div>
                                  :
                                  <div className='full-number'>đ 0</div>
                              }
                            </div>

                            {
                              growth && growth.total_subtotal && growth.total_subtotal !== "0" &&
                              <div className={(growth.total_subtotal.indexOf('-') !== -1) ? "fluctuations reduce" : "fluctuations increase"}>
                                <div className="fluctuation-percent">
                                  {
                                    growth.total_subtotal.indexOf('-') !== -1 ?
                                      <ArrowDownIcon className="w-3 h-3 icon-down" />
                                      :
                                      <ArrowUpIcon className="w-3 h-3 icon-up" />
                                  }

                                  {
                                    growth.total_subtotal === 'Infinity' ?
                                      <div>∞ {getSuffix()}</div>
                                      :
                                      <div>
                                        {getGrowthValue(growth.total_subtotal)}% {getSuffix()}
                                      </div>
                                  }
                                </div>
                              </div>
                            }

                          </div>
                        </div>
                      </SwiperSlide>

                      <SwiperSlide className='!h-auto'>
                        <div className="carousel-item">
                          <div className="carousel-icon-group total-orders">
                            <img src="/images/icons/icon-total-orders.svg" alt="icon-overview" />
                          </div>

                          <div className="carousel-info">
                            <div className="info-label">
                              <div>{t('orders')}</div>

                              <LightTooltip title={t("tooltip-orders")} className="w-[200px]" placement='top-start'>
                                <img src="/images/icons/tooltip.svg" alt="icon-tooltip" className="icon-tooltip" />
                              </LightTooltip>
                            </div>

                            <div className="info-main-value">
                              <div className='shorten'>{shortenNumber(stats.total_orders)}</div>
                              {
                                stats.total_orders > 0 ?
                                  <div className='full-number'>{stats.total_orders.toLocaleString('de-DE')}</div>
                                  :
                                  <div className='full-number'>0</div>
                              }
                            </div>

                            {
                              growth && growth.total_orders && growth.total_orders !== "0" &&
                              <div className={(growth.total_orders.indexOf('-') !== -1) ? "fluctuations reduce" : "fluctuations increase"}>
                                <div className="fluctuation-percent">
                                  {
                                    growth.total_orders.indexOf('-') !== -1 ?
                                      <ArrowDownIcon className="w-3 h-3 icon-down" />
                                      :
                                      <ArrowUpIcon className="w-3 h-3 icon-up" />
                                  }

                                  {
                                    growth.total_orders === 'Infinity' ?
                                      <div>∞ {getSuffix()}</div>
                                      :
                                      <div>{getGrowthValue(growth.total_orders)}% {getSuffix()}</div>
                                  }
                                </div>
                              </div>
                            }

                            <a href={`/orders`}>
                              <div className="view-more">{t('view-more')}</div>
                            </a>
                          </div>
                        </div>
                      </SwiperSlide>

                      <SwiperSlide className='!h-auto'>
                        <div className="carousel-item">
                          <div className="carousel-icon-group total-tickets">
                            <img src="/images/icons/icon-total-tickets.svg" alt="icon-overview" />
                          </div>

                          <div className="carousel-info">
                            <div className="info-label">
                              <div>{t('total-tickets')}</div>

                              <LightTooltip title={t("tooltip-tickets")} className="w-[200px]" placement='top-start'>
                                <img src="/images/icons/tooltip.svg" alt="icon-tooltip" className="icon-tooltip" />
                              </LightTooltip>
                            </div>

                            <div className="info-main-value">
                              <div className='shorten'>{shortenNumber(stats.total_total_item)}</div>
                              {
                                stats.total_total_item > 0 ?
                                  <div className='full-number'>{stats.total_total_item.toLocaleString('de-DE')}</div>
                                  :
                                  <div className='full-number'>0</div>
                              }
                            </div>

                            {
                              growth && growth.total_total_item && growth.total_total_item !== "0" &&
                              <div className={(growth.total_total_item.indexOf('-') !== -1) ? "fluctuations reduce" : "fluctuations increase"}>
                                <div className="fluctuation-percent">
                                  {
                                    growth.total_total_item.indexOf('-') !== -1 ?
                                      <ArrowDownIcon className="w-3 h-3 icon-down" />
                                      :
                                      <ArrowUpIcon className="w-3 h-3 icon-up" />
                                  }

                                  {
                                    growth.total_total_item === 'Infinity' ?
                                      <div>∞ {getSuffix()}</div>
                                      :
                                      <div>{getGrowthValue(growth.total_total_item)}% {getSuffix()}</div>
                                  }
                                </div>
                              </div>
                            }

                          </div>
                        </div>
                      </SwiperSlide>

                      <SwiperSlide className='!h-auto'>
                        <div className="carousel-item">
                          <div className="carousel-icon-group net-revenue">
                            <img src="/images/icons/icon-net-revenue.svg" alt="icon-overview" />
                          </div>

                          <div className="carousel-info">
                            <div className="info-label">
                              <div>{t('net-revenue')}</div>

                              <LightTooltip title={t("tooltip-net-revenue")} className="w-[200px]" placement="left">
                                <img src="/images/icons/tooltip.svg" alt="icon-tooltip" className="icon-tooltip" />
                              </LightTooltip>
                            </div>

                            <div className="info-main-value">
                              <div className='shorten'>đ {shortenNumber(stats.total_grand_total)}</div>

                              {
                                stats.total_grand_total > 0 ?
                                  <div className='full-number'>đ {stats.total_grand_total.toLocaleString('de-DE')}</div>
                                  :
                                  <div className='full-number'>đ 0</div>
                              }
                            </div>

                            {
                              growth && growth.total_grand_total && growth.total_grand_total !== "0" &&
                              <div className={(growth.total_grand_total.indexOf('-') !== -1) ? "fluctuations reduce" : "fluctuations increase"}>
                                <div className="fluctuation-percent">
                                  {
                                    growth.total_grand_total.indexOf('-') !== -1 ?
                                      <ArrowDownIcon className="w-3 h-3 icon-down" />
                                      :
                                      <ArrowUpIcon className="w-3 h-3 icon-up" />
                                  }

                                  {
                                    growth.total_grand_total === 'Infinity' ?
                                      <div>∞ {getSuffix()}</div>
                                      :
                                      <div>{getGrowthValue(growth.total_grand_total)}% {getSuffix()}</div>
                                  }
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </SwiperSlide>

                      <SwiperSlide className='!h-auto'>
                        <div className="carousel-item">
                          <div className="carousel-icon-group total-discount">
                            <img src="/images/icons/icon-total-discount.svg" alt="icon-overview" />
                          </div>

                          <div className="carousel-info">
                            <div className="info-label">
                              <div>{t('total-discount')}</div>

                              <LightTooltip title={t("tooltip-discount")} className="w-[200px]" placement='top-start'>
                                <img src="/images/icons/tooltip.svg" alt="icon-tooltip" className="icon-tooltip" />
                              </LightTooltip>
                            </div>

                            <div className="info-main-value">
                              <div className='shorten'>đ {shortenNumber(stats.total_discount_coupon)}</div>
                              {
                                stats.total_discount_coupon > 0 ?
                                  <div className='full-number'>đ {stats.total_discount_coupon.toLocaleString('de-DE')}</div>
                                  :
                                  <div className='full-number'>đ 0</div>
                              }
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>

                      <SwiperSlide className='!h-auto'>
                        <div className="carousel-item">
                          <div className="carousel-icon-group total-participants">
                            <img src="/images/icons/icon-total-participants.svg" alt="icon-overview" />
                          </div>

                          <div className="carousel-info">
                            <div className="info-label">
                              <div>{t('total-participants')}</div>

                              <LightTooltip title={t("tooltip-participants")} className="w-[200px]" placement='top-start'>
                                <img src="/images/icons/tooltip.svg" alt="icon-tooltip" className="icon-tooltip" />
                              </LightTooltip>
                            </div>

                            <div className="info-main-value">
                              <div className='shorten'>{shortenNumber(stats.total_attendant)}</div>
                              {
                                stats.total_attendant > 0 ?
                                  <div className='full-number'>{stats.total_attendant.toLocaleString('de-DE')}</div>
                                  :
                                  <div className='full-number'>0</div>
                              }
                            </div>

                            {
                              growth && growth.total_attendant && growth.total_attendant !== "0" &&
                              <div className={(growth.total_attendant.indexOf('-') !== -1) ? "fluctuations reduce" : "fluctuations increase"}>
                                <div className="fluctuation-percent">
                                  {
                                    growth.total_attendant.indexOf('-') !== -1 ?
                                      <ArrowDownIcon className="w-3 h-3 icon-down" />
                                      :
                                      <ArrowUpIcon className="w-3 h-3 icon-up" />
                                  }

                                  {
                                    growth.total_attendant === 'Infinity' ?
                                      <div>∞ {getSuffix()}</div>
                                      :
                                      <div>{getGrowthValue(growth.total_attendant)}% {getSuffix()}</div>
                                  }
                                </div>
                              </div>
                            }

                            <a href='/attendees/sports'>
                              <div className="view-more">{t('view-more')}</div>
                            </a>
                          </div>
                        </div>
                      </SwiperSlide>

                      <SwiperSlide className='!h-auto'>
                        <div className="carousel-item">
                          <div className="carousel-icon-group total-promotion">
                            <img src="/images/icons/icon-total-promotion.svg" alt="icon-overview" />
                          </div>

                          <div className="carousel-info">
                            <div className="info-label">
                              <div>{t('total-promotion')}</div>

                              <LightTooltip title={t("tooltip-promotion")} className="w-[200px]" placement="left">
                                <img src="/images/icons/tooltip.svg" alt="icon-tooltip" className="icon-tooltip" />
                              </LightTooltip>
                            </div>

                            <div className="info-main-value">
                              <div className='shorten'>đ {shortenNumber(stats.total_discount_promotion)}</div>
                              {
                                stats.total_discount_promotion > 0 ?
                                  <div className='full-number'>đ {stats.total_discount_promotion.toLocaleString('de-DE')}</div>
                                  :
                                  <div className='full-number'>đ 0</div>
                              }
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    </Swiper>
                    {/* Navigation */}
                    <button className="icon-arrow next-arrow">
                      <ArrowRightIcon className="w-4 h-4 text-brand-500 arrow" />
                    </button>
                  </div>
                  :
                  <CarouselMobile
                    responsive={responsive}
                    infinite={true}
                    arrows={false}
                    showDots
                    customDot={<CustomDot />}
                  >
                    <div className="carousel-page">
                      <div className="carousel-row">
                        <div className="carousel-item">
                          <div className="carousel-icon-group group-revenue">
                            <img src="/images/icons/icon-money.svg" alt="icon-overview" />
                          </div>

                          <div className="carousel-info">
                            <div className="info-label">
                              <span>{t('gross-revenue')}</span>

                              <LightTooltip title={t("tooltip-revenue")} className="w-[200px]" placement='top-start'>
                                <img src="/images/icons/tooltip.svg" alt="icon-tooltip" className="icon-tooltip" />
                              </LightTooltip>
                            </div>

                            <div className="info-main-value">
                              <span className='shorten'>đ {shortenNumber(stats.total_subtotal)}</span>
                            </div>

                            {
                              growth && growth.total_subtotal && growth.total_subtotal !== "0" &&
                              <div className={(growth.total_subtotal.indexOf('-') !== -1) ? "fluctuations reduce" : "fluctuations increase"}>
                                <div className="fluctuation-percent">
                                  {
                                    growth.total_subtotal.indexOf('-') !== -1 ?
                                      <ArrowDownIcon className="w-3 h-3 icon-down" />
                                      :
                                      <ArrowUpIcon className="w-3 h-3 icon-up" />
                                  }

                                  {
                                    growth.total_subtotal === 'Infinity' ?
                                      <div>∞ {getSuffix()}</div>
                                      :
                                      <div>{getGrowthValue(growth.total_subtotal)}% {getSuffix()}</div>
                                  }
                                </div>
                              </div>
                            }
                          </div>
                        </div>

                        <div className="carousel-item">
                          <div className="carousel-icon-group total-orders">
                            <img src="/images/icons/icon-total-orders.svg" alt="icon-overview" />
                          </div>

                          <div className="carousel-info">
                            <div className="info-label">
                              <span>{t('orders')}</span>

                              <LightTooltip title={t("tooltip-orders")} className="w-[200px]" placement='top-start'>
                                <img src="/images/icons/tooltip.svg" alt="icon-tooltip" className="icon-tooltip" />
                              </LightTooltip>
                            </div>

                            <div className="info-main-value">
                              <span className='shorten'>{shortenNumber(stats.total_orders)}</span>
                            </div>

                            {
                              growth && growth.total_orders && growth.total_orders !== "0" &&
                              <div className={(growth.total_orders.indexOf('-') !== -1) ? "fluctuations reduce" : "fluctuations increase"}>
                                <div className="fluctuation-percent">
                                  {
                                    growth.total_orders.indexOf('-') !== -1 ?
                                      <ArrowDownIcon className="w-3 h-3 icon-down" />
                                      :
                                      <ArrowUpIcon className="w-3 h-3 icon-up" />
                                  }
                                  {
                                    growth.total_orders === 'Infinity' ?
                                      <div>∞ {getSuffix()}</div>
                                      :
                                      <div>{getGrowthValue(growth.total_orders)}% {getSuffix()}</div>
                                  }
                                </div>
                              </div>
                            }

                            <a href="/orders">
                              <div className="view-more">{t('view-more')}</div>
                            </a>
                          </div>
                        </div>
                      </div>

                      <div className="carousel-row">
                        <div className="carousel-item">
                          <div className="carousel-icon-group total-tickets">
                            <img src="/images/icons/icon-total-tickets.svg" alt="icon-overview" />
                          </div>

                          <div className="carousel-info">
                            <div className="info-label">
                              <span>{t('total-tickets')}</span>

                              <LightTooltip title={t("tooltip-tickets")} className="w-[200px]" placement='top-start'>
                                <img src="/images/icons/tooltip.svg" alt="icon-tooltip" className="icon-tooltip" />
                              </LightTooltip>
                            </div>

                            <div className="info-main-value">
                              <span className='shorten'>{shortenNumber(stats.total_total_item)}</span>
                            </div>

                            {
                              growth && growth.total_total_item && growth.total_total_item !== "0" &&
                              <div className={(growth.total_total_item.indexOf('-') !== -1) ? "fluctuations reduce" : "fluctuations increase"}>
                                <div className="fluctuation-percent">
                                  {
                                    growth.total_total_item.indexOf('-') !== -1 ?
                                      <ArrowDownIcon className="w-3 h-3 icon-down" />
                                      :
                                      <ArrowUpIcon className="w-3 h-3 icon-up" />
                                  }

                                  {
                                    growth.total_total_item === 'Infinity' ?
                                      <div>∞ {getSuffix()}</div>
                                      :
                                      <div>{getGrowthValue(growth.total_total_item)}% {getSuffix()}</div>
                                  }
                                </div>
                              </div>
                            }
                          </div>
                        </div>

                        <div className="carousel-item">
                          <div className="carousel-icon-group net-revenue">
                            <img src="/images/icons/icon-net-revenue.svg" alt="icon-overview" />
                          </div>

                          <div className="carousel-info">
                            <div className="info-label">
                              <span>{t('net-revenue')}</span>

                              <LightTooltip title={t("tooltip-net-revenue")} className="w-[200px]" placement='top-start'>
                                <img src="/images/icons/tooltip.svg" alt="icon-tooltip" className="icon-tooltip" />
                              </LightTooltip>
                            </div>

                            <div className="info-main-value">
                              <span className='shorten'>đ {shortenNumber(stats.total_grand_total)}</span>
                            </div>

                            {
                              growth && growth.total_grand_total && growth.total_grand_total !== "0" &&
                              <div className={(growth.total_grand_total.indexOf('-') !== -1) ? "fluctuations reduce" : "fluctuations increase"}>
                                <div className="fluctuation-percent">
                                  {
                                    growth.total_grand_total.indexOf('-') !== -1 ?
                                      <ArrowDownIcon className="w-3 h-3 icon-down" />
                                      :
                                      <ArrowUpIcon className="w-3 h-3 icon-up" />
                                  }

                                  <span>{getGrowthValue(growth.total_grand_total)}% {getSuffix()}</span>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="carousel-page">
                      <div className="carousel-row">
                        <div className="carousel-item">
                          <div className="carousel-icon-group total-discount">
                            <img src="/images/icons/icon-total-discount.svg" alt="icon-overview" />
                          </div>

                          <div className="carousel-info">
                            <div className="info-label">
                              <span>{t('total-discount')}</span>

                              <LightTooltip title={t("tooltip-discount")} className="w-[200px]" placement='top-start'>
                                <img src="/images/icons/tooltip.svg" alt="icon-tooltip" className="icon-tooltip" />
                              </LightTooltip>
                            </div>

                            <div className="info-main-value">
                              <span className='shorten'>{shortenNumber(stats.total_discount_coupon)}</span>
                            </div>

                          </div>
                        </div>

                        <div className="carousel-item">
                          <div className="carousel-icon-group total-participants">
                            <img src="/images/icons/icon-total-participants.svg" alt="icon-overview" />
                          </div>

                          <div className="carousel-info">
                            <div className="info-label">
                              <span>{t('total-participants')}</span>

                              <LightTooltip title={t("tooltip-participants")} className="w-[200px]" placement='top-start'>
                                <img src="/images/icons/tooltip.svg" alt="icon-tooltip" className="icon-tooltip" />
                              </LightTooltip>
                            </div>

                            <div className="info-main-value">
                              <span className='shorten'>{shortenNumber(stats.total_attendant)}</span>
                            </div>

                            {
                              growth && growth.total_attendant && growth.total_attendant !== "0" &&
                              <div className={(growth.total_attendant.indexOf('-') !== -1) ? "fluctuations reduce" : "fluctuations increase"}>
                                <div className="fluctuation-percent">
                                  {
                                    growth.total_attendant.indexOf('-') !== -1 ?
                                      <ArrowDownIcon className="w-3 h-3 icon-down" />
                                      :
                                      <ArrowUpIcon className="w-3 h-3 icon-up" />
                                  }

                                  <span>{getGrowthValue(growth.total_attendant)}% {getSuffix()}</span>
                                </div>
                              </div>
                            }

                            <a href='/attendees/sports'>
                              <div className="view-more">{t('view-more')}</div>
                            </a>
                          </div>
                        </div>

                      </div>

                      <div className="carousel-row">
                        <div className="carousel-item">
                          <div className="carousel-icon-group total-promotion">
                            <img src="/images/icons/icon-total-promotion.svg" alt="icon-overview" />
                          </div>

                          <div className="carousel-info">
                            <div className="info-label">
                              <span>{t('total-promotion')}</span>

                              <LightTooltip title={t("tooltip-promotion")} className="w-[200px]" placement='top-start'>
                                <img src="/images/icons/tooltip.svg" alt="icon-tooltip" className="icon-tooltip" />
                              </LightTooltip>
                            </div>

                            <div className="info-main-value">
                              <span className='shorten'>{shortenNumber(stats.total_discount_promotion)}</span>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </CarouselMobile>
              }

            </div>
          }

        </div>

        {
          showEvent &&
          <Events
            t={t}
            applyDate={applyDate}
            page={page}
            setPage={setPage}
            setLoading={setLoading}
            setMessage={setMessage}
            setMessageType={setMessageType}
            setFilter={setFilter}
            filter={filter}
            shortenNumber={shortenNumber}
            language={language}
            mobile={mobile}
            setEventSelected={setEventSelected}
            setCurrentTab={setCurrentTab}
            setEventID={setEventID}
            eventID={eventID}
            eventStatus={eventStatus}
            setEventStatus={setEventStatus}
            navigate={navigate}
          />
        }

        <ChartOverview
          t={t}
          language={language}
          mobile={mobile}
          setMessage={setMessage}
          setMessageType={setMessageType}
          setLoading={setLoading}
          dateType={dateType}
          setDateType={setDateType}
          dateValue={dateValue}
          setDateValue={setDateValue}
        />

        {
          message !== '' &&
          <Message
            message={message}
            messageType={messageType}
            setMessage={setMessage}
          />
        }

        {
          loading &&
          <Loader />
        }
      </div>
    )
  } else {
    return <></>
  }

}

export default Overview;