import DatePickerChart from "@components/common/DatePickerChart";
import ApiService from "@services/ApiService";
import moment from "moment";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";

import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const ChartOverview = (props: any) => {
  const { t, mobile, setLoading, setMessage, setMessageType, dateType, setDateType, dateValue, setDateValue } = props;
  const [displayChart, setDisplayChart] = useState(true);
  const [dataChart, setDataChart] = useState(null as any);
  const [chartOptions, setChartOptions] = useState(null as any);
  const [chartSeries, setChartSeries] = useState(null as any);
  const [filter, setFilter] = useState(false);

  useEffect(() => {
    if (dateValue === '') {
      const year = moment().format('YYYY');

      setDateValue(year);

      setFilter(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateValue]);

  useEffect(() => {
    if (filter) {
      fetchDataChart();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const fetchDataChart = async () => {
    setLoading(true);
    try {
      const result = await ApiService.get(`dashboards/orders/charts?type=${dateType}&value=${dateValue}`);

      if (result && Object.keys(result).length > 0) {
        setDisplayChart(true);
        setDataChart(result);
      } else {
        setDisplayChart(false);
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }
    setFilter(false);
    setLoading(false);
  }

  useEffect(() => {
    if (dataChart) {
      handleDisplayChart();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataChart]);

  const handleDisplayChart = () => {
    let cates = [] as any;
    const net_revenue = [] as any;
    const gross_revenue = [] as any;
    const total_item = [] as any;
    const keys = Object.keys(dataChart);

    keys.forEach((item: any) => {
      let date = '';
      if (dateType === 'month') {
        const month = dateValue.slice(0, 2);
        date = (+item < 10) ? `0${item}/${month}` : `${item}/${month}`;
      } else if (dateType === 'quarter') {
        date = `Q${item}`;
      } else {
        date = item;
      }

      net_revenue.push(dataChart[item].grand_total);
      gross_revenue.push(dataChart[item].subtotal);
      total_item.push(dataChart[item].total_item);
      cates.push(date);
    });

    const options = {
      chart: {
        id: "multi-series-chart",
        toolbar: {
          show: false,
        },
        stacked: false,
      },
      xaxis: { categories: cates },
      yaxis: [
        {
          seriesName: t('gross-revenue-event'),
          title: { text: t('chart-revenue') },
          labels: {
            formatter: function (value: any) {
              let last_value = '';
              if (value > 0) {
                if (value >= 1000000000000) {
                  let shortened = (value / 1000000000000) as any;
                  shortened = (Math.floor(shortened * 10) / 10).toFixed(1);
                  last_value = shortened.endsWith('.0') ? shortened.slice(0, -2) + 'T' : shortened + 'T';
                } else if (value >= 1000000000) {
                  let shortened = (value / 1000000000) as any;
                  shortened = (Math.floor(shortened * 10) / 10).toFixed(1);
                  last_value = shortened.endsWith('.0') ? shortened.slice(0, -2) + 'B' : shortened + 'B';
                } else if (value >= 1000000) {
                  let shortened = (value / 1000000) as any;
                  shortened = (Math.floor(shortened * 10) / 10).toFixed(1);
                  last_value = shortened.endsWith('.0') ? shortened.slice(0, -2) + 'M' : shortened + 'M';
                } else if (value >= 1000) {
                  let shortened = (value / 1000) as any;
                  shortened = (Math.floor(shortened * 10) / 10).toFixed(1);
                  last_value = shortened.endsWith('.0') ? shortened.slice(0, -2) + 'K' : shortened + 'K';
                } else {
                  last_value = value;
                }

                return `đ ${last_value}`;
              } else {
                return 0;
              }
            },
          },
        },
        {
          seriesName: t('gross-revenue-event'),
          title: { text: t('chart-revenue') },
          labels: {
            formatter: function (value: any) {
              let last_value = '';
              if (value > 0) {
                if (value >= 1000000000000) {
                  let shortened = (value / 1000000000000) as any;
                  shortened = (Math.floor(shortened * 10) / 10).toFixed(1);
                  last_value = shortened.endsWith('.0') ? shortened.slice(0, -2) + 'T' : shortened + 'T';
                } else if (value >= 1000000000) {
                  let shortened = (value / 1000000000) as any;
                  shortened = (Math.floor(shortened * 10) / 10).toFixed(1);
                  last_value = shortened.endsWith('.0') ? shortened.slice(0, -2) + 'B' : shortened + 'B';
                } else if (value >= 1000000) {
                  let shortened = (value / 1000000) as any;
                  shortened = (Math.floor(shortened * 10) / 10).toFixed(1);
                  last_value = shortened.endsWith('.0') ? shortened.slice(0, -2) + 'M' : shortened + 'M';
                } else if (value >= 1000) {
                  let shortened = (value / 1000) as any;
                  shortened = (Math.floor(shortened * 10) / 10).toFixed(1);
                  last_value = shortened.endsWith('.0') ? shortened.slice(0, -2) + 'K' : shortened + 'K';
                } else {
                  last_value = value;
                }

                return `đ ${last_value}`;
              } else {
                return 0;
              }
            },
          },
        },
        {
          seriesName: t('chart-ticket'),
          opposite: true,
          title: { text: t('chart-ticket') },
          labels: {
            formatter: function (value: any) {
              if (value > 0) {
                if (value >= 1000000000000) {
                  let shortened = (value / 1000000000000) as any;
                  shortened = (Math.floor(shortened * 10) / 10).toFixed(1);
                  return shortened.endsWith('.0') ? shortened.slice(0, -2) + 'T' : shortened + 'T';
                } else if (value >= 1000000000) {
                  let shortened = (value / 1000000000) as any;
                  shortened = (Math.floor(shortened * 10) / 10).toFixed(1);
                  return shortened.endsWith('.0') ? shortened.slice(0, -2) + 'B' : shortened + 'B';
                } else if (value >= 1000000) {
                  let shortened = (value / 1000000) as any;
                  shortened = (Math.floor(shortened * 10) / 10).toFixed(1);
                  return shortened.endsWith('.0') ? shortened.slice(0, -2) + 'M' : shortened + 'M';
                } else if (value >= 1000) {
                  let shortened = (value / 1000) as any;
                  shortened = (Math.floor(shortened * 10) / 10).toFixed(1);
                  return shortened.endsWith('.0') ? shortened.slice(0, -2) + 'K' : shortened + 'K';
                } else {
                  return value;
                }
              } else {
                return 0;
              }
            }
          }
        },
      ],
      colors: ["#1B9DA0", "#EEAE0E", "#8044FF"],
      tooltip: {
        y: {
          formatter: (value: any, series: any) => {
            if (series.seriesIndex < 2) {
              return `đ ${value.toLocaleString()}`;
            } else {
              return `${value.toLocaleString()}`;
            }
          },
        },
      },
    };

    const series = [
      { name: t('gross-revenue-event'), data: gross_revenue, type: 'line', yAxisIndex: 0 },
      { name: t('net-revenue-event'), data: net_revenue, type: 'line', yAxisIndex: 0 },
      { name: t('chart-ticket'), data: total_item, type: 'line', yAxisIndex: 1},
    ];

    setChartOptions(options);

    setChartSeries(series);
  }

  return (
    <div className="chart-board">
      <div className="chart-header">
        <div className="header-label">{t("sale-tickets")}
          <LightTooltip title={t("tooltip-chart")} className="w-[200px]" placement="top-start">
            <img src="/images/icons/tooltip.svg" alt="icon-tooltip" className="icon-tooltip" />
          </LightTooltip>
        </div>

        <div className="filters">
          {
            !mobile &&
            <div className="label">{t('time')}</div>
          }

          <div className="date-picker">
            <DatePickerChart
              mobile={mobile}
              setDateType={setDateType}
              setDateValue={setDateValue}
              dateType={dateType}
              dateValue={dateValue}
              setFilter={setFilter}
            />
          </div>
        </div>

      </div>

      <div className="chart-body">
        {
          displayChart &&
          <div id="chart">
            {
              (chartOptions && chartSeries) &&
              <Chart options={chartOptions} series={chartSeries} type="line" />
            }
          </div>
        }

      </div>
    </div>
  )
}

export default ChartOverview;