import { useState, useEffect } from 'react';
import useWindowWidth from "@helpers/InitWidthDevice";
import { Formik, Field, Form } from 'formik';
import Message from '@components/common/Message';
import Loader from '@components/common/Loader';
import { Button, Switch, Select, Option } from "@material-tailwind/react";
import { COUNTRY } from '@helpers/constants';
import { useTranslation } from "react-i18next";
import MultiSelect from 'react-select';
import ApiService from '@services/ApiService';
import EditAccountUI from 'UI/desktop/Organization/AccountManagement/Edit/EditAccount';
import EditAccountUIMobile from 'UI/mobile/Organization/AccountManagement/Edit/EditAccount';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const FormEditAccount = (props: any) => {
  const { t } = props;
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(true);
  const [role, setRole] = useState('');
  const [event, setEvent] = useState([]);
  const [eventDefault, setEventDefault] = useState([]);
  const { slug } = useParams();
  const [account, setAccount] = useState(null as any);
  const [phoneCode, setPhoneCode] = useState('84');
  const [roleList, setRoleList] = useState<Object[]>([]);

  const { i18n: { language } = {} } = useTranslation();
  const [events, setEvents] = useState([] as any);
  const [error, setError] = useState(null as any);
  const [active, setActive] = useState(true);
  const [fetchData, setFetchData] = useState(false);

  useEffect(() => {
    if (slug && slug !== '') {
      const intervalFetchData = setInterval(() => {
        setFetchData(true);
        clearInterval(intervalFetchData);
      }, 100);
    }
  }, [slug]);

  useEffect(() => {
    async function fetchRoleInfo() {
      setLoading(true);
      try {
        const result = await ApiService.get(`account/${slug}`);

        if (result) {
          setAccount(result);
          setStatus(result.is_active);
          setPhoneCode(result.phone_area_code);
          if (result.role_name && result.role_name !== '' && result.role_name.toLowerCase() === 'owner') {
            setActive(false);
          }
        }
      } catch (error: any) {
        setMessage(error.message);
        setMessageType('error');
        setLoading(false);
      }
    }

    if (fetchData) {
      fetchRoleInfo();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  useEffect(() => {
    async function fetchListRole() {
      try {
        const result = await ApiService.get(`role/all/ids`);

        if (result) {
          const roles = [] as any;
          result.forEach((item: any) => {
            if (item.name.toLowerCase() !== "owner") {
              const obj = {
                value: item.id,
                label: item.name
              };

              roles.push(obj);

              if (item.id === account.role_id) {
                setRole(item.id);
              }
            }
          });

          setRoleList([...roles]);
        }
      } catch (error: any) {
        setMessage(error.message);
        setMessageType('error');
        setLoading(false);
      }
    }

    if (account) {
      fetchListRole();
    }
  }, [account, t]);

  useEffect(() => {
    async function fetchEventList() {
      try {
        const result = await ApiService.get(`events`);

        if (result) {
          const arr = [] as any;

          result.forEach((item: any) => {
            const obj = {
              value: item.id,
              label: get(item, `name_${language}`, '')
            };

            arr.push(obj);
          });

          setEvents(arr);

          if (account && account.event_ids && account.event_ids.length > 0) {
            const eventSelected = [] as any;

            for (let i = 0; i < arr.length; i++) {
              account.event_ids.forEach((item: any) => {
                if (item === arr[i].value) {
                  eventSelected.push(arr[i]);
                }
              })
            }

            setEventDefault(eventSelected);
            setEvent(eventSelected);
          }

          setLoading(false);
        }
      } catch (error: any) {
        setMessage(error.message);
        setMessageType('error');
        setLoading(false);
      }
    }

    if (roleList.length > 0) {
      fetchEventList();
    }
  }, [roleList, language, account]);

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    setLoading(true);

    const eventSelected: Array<string> = [];

    event.forEach((item: any) => {
      eventSelected.push(item.value);
    })

    const submitData = {
      "id": slug,
      "email": account.email,
      "first_name": values.first_name,
      "last_name": values.last_name,
      "role_id": role,
      "phone_area_code": phoneCode,
      "phone": values.phone,
      "event_ids": eventSelected,
      "is_active": status
    }

    if (!error) {
      try {
        const result = await ApiService.put(`account/${slug}`, submitData);

        if (result) {
          setMessage(t("update-successfully"));
          setMessageType("success");

          const intervalRedirect = setInterval(() => {
            const href = window.location.href;

            window.location.href = href.replace("/edit", "/detail");
            clearInterval(intervalRedirect);
          }, 2000);
        }
      } catch (error: any) {
        setMessage(error.message);
        setMessageType('error');
      }
    }

    setSubmitting(false);
    setLoading(false);
  }

  const validateText = (value: string) => {
    let error;

    if (!value) {
      error = t("error-field-require");
    } else {
      let str = value;
      str = str.toLowerCase();
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
      str = str.replace(/đ/g, 'd');
      const regex = /[^a-zA-Z0-9 ]+/;

      if (regex.test(str)) {
        error = t("error-special-character");
      }
    }

    return error;
  }

  if (active) {
    return (
      <div className="form-create-account">
        {
          account &&
          <Formik
            initialValues={{ first_name: account.first_name, last_name: account.last_name, phone: account.phone }}
            onSubmit={(values, { setSubmitting }) => handleSubmit(values, { setSubmitting })}
          >
            {({
              handleSubmit,
              isSubmitting
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="form">
                  <div className="form-header">{t('edit-account')}</div>
                  <div className="form-body">
                    <div className="form-row">
                      <div className="form-item">
                        <div className="form-label-group">
                          <span className="form-label">{t('status')}</span>

                          <img src="/images/icons/icon-require.svg" alt="icon-require" className="icon-require" />
                        </div>

                        <Switch
                          checked={status}
                          onChange={() => setStatus(!status)}
                          crossOrigin="switch"
                          ripple={false}
                          className="h-full w-full checked:bg-[#0162D1] switch"
                          containerProps={{
                            className: "w-11 h-6",
                          }}
                          circleProps={{
                            className: "before:hidden left-0.5 border-none",
                          }}
                          onPointerEnterCapture="none" onPointerLeaveCapture="none" />
                      </div>

                      <div className="form-item">
                        <div className="form-label-group">
                          <span className="form-label">Email</span>

                          <img src="/images/icons/icon-require.svg" alt="icon-require" className="icon-require" />

                          <LightTooltip title={t('tooltip-email-create-account')} className="w-[200px]" placement='top-start'>
                            <img src="/images/icons/tooltip.svg" alt="icon-tooltip" />
                          </LightTooltip>
                        </div>

                        <div className="field-group">
                          <input type="text" className="input disabled" value={account.email} placeholder='Email' disabled={true} />
                        </div>

                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-item">
                        <div className="form-label-group">
                          <span className="form-label">{t('first-name')}</span>

                          <img src="/images/icons/icon-require.svg" alt="icon-require" className="icon-require" />
                        </div>

                        <Field name="first_name" validate={validateText}>
                          {({
                            field,
                            meta,
                          }: any) => (
                            <div className="field-group">
                              <input {...field} type="text" className="input" autoComplete="off" placeholder={t('first-name')} maxLength={50} />

                              <div className="text-length">{field.value.length}/50</div>

                              {meta.touched && meta.error && (
                                <div className="field-error">{meta.error}</div>
                              )}
                            </div>
                          )}
                        </Field>

                      </div>

                      <div className="form-item">
                        <div className="form-label-group">
                          <span className="form-label">{t('last-name')}</span>

                          <img src="/images/icons/icon-require.svg" alt="icon-require" className="icon-require" />
                        </div>

                        <Field name="last_name" validate={validateText}>
                          {({
                            field,
                            meta,
                          }: any) => (
                            <div className="field-group">
                              <input {...field} type="text" className="input" autoComplete="off" placeholder={t('last-name')} maxLength={50} />

                              <div className="text-length">{field.value.length}/50</div>

                              {meta.touched && meta.error && (
                                <div className="field-error">{meta.error}</div>
                              )}
                            </div>
                          )}
                        </Field>

                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-item">
                        <div className="form-label-group">
                          <span className="form-label">{t('role-name')}</span>

                          <img src="/images/icons/icon-require.svg" alt="icon-require" className="icon-require" />
                        </div>

                        <div className="select-role">
                          {
                            roleList.length > 0 &&
                            <Select
                              value={role}
                              onChange={(val: any) => setRole(val)}
                              placeholder="Select role name"
                              onPointerEnterCapture="none" onPointerLeaveCapture="none"
                            >
                              {
                                roleList.map((item: any, index: number) => {
                                  return (
                                    <Option key={`${item.value}_${index}`}
                                      value={item.value}>{item.label}</Option>
                                  )
                                })
                              }
                            </Select>
                          }

                        </div>
                      </div>

                      <div className="form-item">
                        <div className="form-label-group">
                          <span className="form-label">{t('contact-phone')}</span>
                        </div>

                        <div className="phone-group">
                          <div className="select-phone-code">
                            <Select
                              value={phoneCode}
                              onChange={(val: any) => setPhoneCode(val)}
                              placeholder=""
                              onPointerEnterCapture="none" onPointerLeaveCapture="none"
                            >
                              {
                                COUNTRY.map((item: any, index: number) => {
                                  return (
                                    <Option key={`${item.value}_${index}`}
                                      value={item.code}>{item.value} | +{item.code}</Option>
                                  )
                                })
                              }
                            </Select>
                          </div>

                          <Field name="phone" >
                            {({
                              field,
                              meta,
                            }: any) => (
                              <div className="input-phone-group">
                                <input {...field} type="text" className="input-phone" autoComplete="off" placeholder={t('phone-number')} />
                              </div>
                            )}
                          </Field>
                        </div>

                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-item-full">
                        <div className="form-label-group">
                          <span className="form-label">{t('event')}</span>

                          <img src="/images/icons/icon-require.svg" alt="icon-require" className="icon-require" />
                        </div>

                        {
                          eventDefault.length > 0 &&
                          <MultiSelect
                            defaultValue={eventDefault}
                            isMulti
                            name="categories"
                            options={events}
                            className="multi-select"
                            classNamePrefix="select"
                            placeholder={t('select-event')}
                            onChange={(e: any) => {
                              if (e && e.length > 0) {
                                setEvent(e);
                                setError(null);
                              } else {
                                setError({
                                  event: t('error-field-require')
                                })
                              }
                            }} />
                        }

                        {
                          (error && error.event !== '') &&
                          <div className="field-error">{error.event}</div>
                        }

                      </div>
                    </div>
                  </div>
                </div>

                <div className="btn-group">
                  <Button
                    onPointerEnterCapture="none" onPointerLeaveCapture="none"
                    placeholder="Button Cancel" className="btn-cancel" onClick={() => window.history.back()}>{t('btn-cancel')}</Button>
                  <Button
                    onPointerEnterCapture="none" onPointerLeaveCapture="none"
                    type="submit" disabled={isSubmitting} placeholder="Button Save" className="btn-save">{t('btn-save')}</Button>
                </div>

                {
                  message !== '' &&
                  <Message
                    message={message}
                    messageType={messageType}
                    setMessage={setMessage}
                  />
                }

                {
                  loading &&
                  <Loader />
                }
              </Form>
            )}
          </Formik>
        }

      </div>
    )
  } else {
    return (
      <div className="access-denied">
        <div className="access-denied__label">{t('access-denied')}</div>
        <div className="access-denied__content">{t('access-denied-content')}</div>
        <div className="access-denied__content">{t('access-denied-content-2')}</div>
      </div>
    )
  }
}

const EditAccountManagement = () => {
  const windowWidth = useWindowWidth();
  const { t } = useTranslation();

  if (windowWidth && windowWidth > 768) {
    return (
      <EditAccountUI
        FormEditAccount={FormEditAccount}
        t={t}
      />
    )
  } else {
    return (
      <EditAccountUIMobile
        FormEditAccount={FormEditAccount}
        t={t}
      />
    )
  }
};

export default EditAccountManagement;