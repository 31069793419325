import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';

const CustomNextButton = ({ onClick }: any) => (
  <div onClick={onClick} className="custom-next-button size-[24px] rounded-full bg-white absolute right-[10px] z-[5] cursor-pointer flex items-center justify-center">
    <ChevronRightIcon className='size-[16px]' />
  </div>
);

const CustomPrevButton = ({ onClick }: any) => (
  <div onClick={onClick} className="custom-prev-button size-[24px] rounded-full bg-white absolute left-[10px] z-[5] cursor-pointer flex items-center justify-center">
    <ChevronLeftIcon className='size-[16px]' />
  </div>
);

const TopBanner = (props: any) => {
  const { banners, isMobile } = props;
  return (
    <div className='relative flex items-center'>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        navigation={{
          nextEl: '.custom-next-button',
          prevEl: '.custom-prev-button',
        }}
        loop={true}
        modules={[Autoplay, Pagination, Navigation]}
      >
        {
          banners.map((item: any, index: number) => {
            return (
              <SwiperSlide key={`top_banner_item_${index}`}>
                {
                  (item.open_url && item.open_url !== '') ?
                    <a className="w-full cursor-pointer" href={item.open_url} target="_blank" rel="noreferrer">
                      <img loading="lazy" src={item.banner} className={`w-full`} alt="slide-top-banner" />
                    </a>
                    :
                    <img loading="lazy" src={item.banner} className={`w-full`} alt="slide-top-banner" />
                }

              </SwiperSlide>
            )
          })
        }
      </Swiper>
      
      {
        !isMobile &&
        <CustomPrevButton />
      }

      {
        !isMobile &&
        <CustomNextButton />
      }
    </div>
  )
};

export default TopBanner;